const burgerBtn = document.querySelector('.nav__hamburger-btn')
const nav = document.querySelector('.nav')
const navMobile = document.querySelector('.nav__mobile-links')
const mobileNavLinks = document.querySelectorAll('.nav__mobile-link')
const headerImgOne = document.querySelector('.header__img--one')
const headerImgTwo = document.querySelector('.header__img--two')
const headerImgThree = document.querySelector('.header__img--three')
const year = document.querySelector('.footer__year')

const swapHeaderImg = () => {
	setTimeout(() => {
		headerImgOne.style.opacity = '0'
		headerImgTwo.style.opacity = '1'
	}, 2000)
	setTimeout(() => {
		headerImgTwo.style.opacity = '0'
		headerImgThree.style.opacity = '1'
	}, 4000)
	setTimeout(() => {
		headerImgThree.style.opacity = '0'
		headerImgOne.style.opacity = '1'
	}, 6000)
}

swapHeaderImg()
setInterval(swapHeaderImg, 6000)

const handleMenu = () => {
	let delay = 0

	if (navMobile.classList.contains('active') == false) {
		navMobile.style.right = '0px'
		nav.classList.add('nav__scrolled')
		mobileNavLinks.forEach(el => {
			setTimeout(() => {
				el.style.right = '0'
			}, delay)
			delay += 100
		})
	} else {
		mobileNavLinks.forEach(el => {
			setTimeout(() => {
				el.style.right = '-110%'
			}, delay)
			delay += 100
		})
		setTimeout(() => (navMobile.style.right = '-110%'), 400)
	}
	navMobile.classList.toggle('active')
}

const menuTransparency = () => {
	if (window.scrollY > 20) {
		nav.classList.add('nav__scrolled')
	} else {
		if (navMobile.classList.contains('active') == false) {
			nav.classList.remove('nav__scrolled')
		}
	}
}

window.addEventListener('scroll', menuTransparency)

const setYear = () => {
	let currentYear = new Date().getFullYear()
	year.textContent = currentYear
}

setYear()

burgerBtn.addEventListener('click', handleMenu)
mobileNavLinks.forEach(el =>
	el.addEventListener('click', () => {
		navMobile.style.right = '-110%'
		navMobile.classList.toggle('active')
	})
)

const msgStatus = document.querySelector('.msg-status')

if (document.location.search == '?mail_status=sent') {
	msgStatus.classList.add('success')
	// msgStatus.textContent = 'Wiadomość wysłana'

	setTimeout(() => {
		msgStatus.classList.remove('success')
	}, 3000)
}

if (document.location.search == '?mail_status=error') {
	msgStatus.classList.add('error')
	msgStatus.textContent = 'Wystąpił błąd'

	setTimeout(() => {
		msgStatus.classList.remove('error')
	}, 3000)
}
